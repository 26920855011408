<template>
  <div class="oci-container-pj">
    <div class="oci-header">
      <b-row>
        <b-col class="col-4">
        <div class="float-left">
          <feather-icon
            id="idgoback"
            icon="ChevronLeftIcon"
            size="24"
            @click="GoToBack"
          />
        </div>
        </b-col>
        <b-col class="col-3">
        <div class="float-right">
         Projects
        </div>
        </b-col>
        <b-col class="col-5">   
        <div class="float-right">
          <feather-icon
            id="idrefresh"
            :icon=this.icon
            size="24"
            :color=this.color
            @click="LoadData"
          />
        </div> 
        </b-col>       
      </b-row>          
    </div>
    <hr class="w-100">  
    <!--v-scroll="handleScroll"--> 
    <div class="wrapper">
      <b-table
        v-for="project in projectsList"
        :fields="fields"
        :items="project.job"
        @row-clicked="projectSelected(project.id, project.job.state, project.date)"
        responsive
        small
        borderless
        id="projectstable"
        style="box-shadow: 0px 0px 0px 1px rgba(0,0,0,0.05); border-radius: 8px; margin-top: 6px; margin-bottom: 6px;"
      >    
        
        <template #head(site_main_address)="data">
          <div :style="checkIfToday(project.date, project.job.state)" :id="getId(project.id, project.date)">
            {{ formatDate(project.date) }}
          </div>
        </template>
        <template #head(site_postal_code)="data">
          <div class="text-right">     
          <label :style="getStatusColor(project.job.state)">&nbsp;{{ getJobStatus(project.job.state) }}&nbsp;</label>      
          </div>
        </template>
        <template #cell(site_main_address)="data">
          <div :style="SetAddressStyle(project.date, project.job.state)">
            {{ project.job.site_main_address }}  
          </div>
        </template>          
        <template #cell(site_postal_code)="data">
          <div :style="SetPostcodeStyle(project.date, project.job.state)" class="text-right">
            {{ project.job.site_postal_code }} 
          </div>
        </template>
        
      </b-table>
<!--
      <b-table borderless hover show-empty style="box-shadow: 0px 0px 0px 1px rgba(0,0,0,0.05); border-radius: 8px; margin-top: 6px; margin-bottom: 6px; background-color: white; height: 200px">
          <template #empty="scope" style="align:center">
              There are no other projects
          </template>
      </b-table>
-->
    </div>
    <!--
    <div v-if="this.projectsList.length < 7">
    <b-row align-h="center"> 
      <div class="float-center">
        <feather-icon
          id="notifications"
          icon="ChevronUpIcon"
          size="24"
          @click="scrollUp"
        />
      </div>
      <div class="float-center">
        <feather-icon
          id="notifications"
          icon="ChevronDownIcon"
          size="24"
          @click="scrollDown"
        />
      </div>      
    </b-row>   
    </div>  
    -->
  </div>
</template>
<script>
import {
  BTable, BRow, BCol, BButton, 
} from 'bootstrap-vue'
import { projectsMethods } from '@/components/ui/ProjectsFunctions'
import { checkInOutMethods } from '@/components/ui/CheckInOutFunctions'
import { mapGetters } from 'vuex'
import store from '@/store'
import router from '@/router'

export default {
  components: {
    BTable,
    BRow,
    BCol,
    BButton,
  },
  computed: {
    ...mapGetters({ assignmentID: 'app/getUserAssignmentsID' }),
    ...mapGetters({ projectsList: 'app/getProjectsList' })
  },    
  data() {
    return {
      icon: "RefreshCwIcon",
      color: "#6E6B7B",
      scrollPosition: 0,
      message: '',
      showMessage: false,
      showLogin: false,
      fields: [   
        // {
        //  key: 'state', label: '', thClass: 'bg-white', tdClass: 'bg-white', // tdClass: this.tdClassFunc,
        // },         
        {
          key: 'site_main_address', label: 'site_main_address', thClass: 'bg-white', tdClass: 'bg-white', 
        },
        {
          key: 'site_postal_code', label: 'site_postal_code', thClass: 'bg-white', tdClass: 'bg-white', 
        },          
      ],
    }
  },
  created() {    
    this.LoadData()  
  },  
  methods: {
    LoadData() {
      console.log('project loaddata')
      store.commit('app/setGetNextJob', false)
      this.color = "#49A7EA"

      store.commit('app/setIsFromProjectListPage', false);
      const mainDate = new Date()
      mainDate.setDate(mainDate.getDate()-7) // 7 days before untill all in future

      store.commit('app/setPreviousAssignmentsID', this.assignmentID)
      this.$store.dispatch('app/getJobsList', mainDate)           

      this.$store.dispatch('app/getNewNotificationList') // put this in a timer
      
      setTimeout(() => this.color = "#6E6B7B", 250); // not ideal, but for now!!!

      setTimeout(() => this.SetScrollOnTodayJob(), 700); // not ideal, but for now!!!
      // this.SetScrollOnTodayJob()
    },    
    SetScrollOnTodayJob() {
      var element = document.getElementById("special");
      if(element) { 
        element.scrollIntoView();  
      }
    },     
    formatDate(data) {
      const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
      ];      
      return String(data.day) +' '+ monthNames[data.month-1]+' '+ String(data.year)      
    },
    GoToBack() {
      // console.log('GoToBack')
      // console.log(this.assignmentID)
      // store.commit('app/changeUserAssignmentsID', this.assignmentID)
      this.$router.go(-1)
    },    
    getStateCSS(state) {
      console.log(state)
      if(state === 'accepted')
        return 'text-left bg-success'
      if(state === 'unable_to_attend')
        return 'text-left bg-danger'        
      if(state === 'on_site')
        return 'text-left bg-success'        
      if(state === "offered")
        return 'text-left bg-primary'   
      if(state === 'rejected')
        return 'text-left bg-danger'    
      if(state === 'unaccepted')
        return 'text-left bg-danger'               
    },   
    getJobStatus(state) {    
      // console.log(state)  
      if(state === 'offered')
        return 'offered'  
      else if(state === 'unaccepted')
        return 'rejected'
      else if(state === 'on_site')
        return 'accepted'    
      else if(state === 'unable_to_attend')
        return 'unabletoattend'         
      else if(state === 'no_show')
        return 'noshow'             
      else
        return state 
    },   
    tdClassFunc(row) {
      //if (row > 50) {
      //  return 'bg-primary';
      //}
      //return 'bg-primary';
    },
    handleScroll({ target: { scrollTop, clientHeight, scrollHeight }}){  
      // console.log(scrollTop)       
      // console.log(clientHeight)       
      // console.log(scrollHeight)       
      if (scrollTop + clientHeight >= scrollHeight) {
        // check the date of the last in the list and ask for next 5 days        
        console.log('bottom')
        if (this.projectsList.length > 0){
          // console.log(this.projectsList[this.projectsList.length-1].date.year)
          // console.log(this.projectsList[this.projectsList.length-1].date.month)
          // console.log(this.projectsList[this.projectsList.length-1].date.day)
          const mainDate = new Date(this.projectsList[this.projectsList.length-1].date.year, this.projectsList[this.projectsList.length-1].date.month-1, this.projectsList[this.projectsList.length-1].date.day)
          mainDate.setDate(mainDate.getDate() - 4)
          
          this.$store.dispatch('app/getJobsList', mainDate) //, DESC
        }         
      } else if (scrollTop == 0) {        // scrollTop + clientHeight < scrollHeight
        // check the date of the first in the list and ask for previous 5 days
        console.log('top')     
        if (this.projectsList.length > 0){
          // console.log(this.projectsList[0].date.year)
          // console.log(this.projectsList[0].date.month)
          // console.log(this.projectsList[0].date.day)
          const mainDate = new Date(this.projectsList[0].date.year, this.projectsList[0].date.month-1, this.projectsList[0].date.day)
          mainDate.setDate(mainDate.getDate() - 4)  

          this.$store.dispatch('app/getJobsList', mainDate) //, DESC
        }  
      } else {
        console.log('scrolling')
      }
    },
    scrollUp(){
      if (this.projectsList.length > 0){
        // console.log(this.projectsList[0].date.year)
        // console.log(this.projectsList[0].date.month)
        // console.log(this.projectsList[0].date.day)
        const mainDate = new Date(this.projectsList[0].date.year, this.projectsList[0].date.month-1, this.projectsList[0].date.day)
        mainDate.setDate(mainDate.getDate() - 4)  

        this.$store.dispatch('app/getJobsList', mainDate) //, DESC
      }else{
        const mainDate = new Date()
        mainDate.setDate(mainDate.getDate())
        this.$store.dispatch('app/getJobsList', mainDate)        
      }             
    },
    scrollDown(){
      if (this.projectsList.length > 0){
        // console.log(this.projectsList[this.projectsList.length-1].date.year)
        // console.log(this.projectsList[this.projectsList.length-1].date.month)
        // console.log(this.projectsList[this.projectsList.length-1].date.day)
        const mainDate = new Date(this.projectsList[this.projectsList.length-1].date.year, this.projectsList[this.projectsList.length-1].date.month-1, this.projectsList[this.projectsList.length-1].date.day)
        mainDate.setDate(mainDate.getDate() - 4)
        
        this.$store.dispatch('app/getJobsList', mainDate) //, DESC
      }else{
        const mainDate = new Date()
        mainDate.setDate(mainDate.getDate())
        this.$store.dispatch('app/getJobsList', mainDate)        
      } 
    },    
    getStatusColor(state){
      // return 'color: red' 
      if(state === 'accepted') 
        return 'color: #49A7EA; background-color: rgba(73, 167, 234, 0.2); border-radius: 25px;'
      if(state === 'unable_to_attend') 
        return 'color: #EA5455; background-color: rgba(234, 84, 85, 0.2); border-radius: 25px;'       
      if(state === 'no_show') 
        return 'color: #EA5455; background-color: rgba(234, 84, 85, 0.2); border-radius: 25px;'         
      if(state === 'on_site') 
        return 'color: #49A7EA; background-color: rgba(73, 167, 234, 0.2); border-radius: 25px;'        
      if(state === 'offered') 
        return 'color: #FF9F43; background-color: rgba(255, 159, 67, 0.2); border-radius: 25px;'   
      if(state === 'rejected') 
        return 'color: #EA5455; background-color: rgba(234, 84, 85, 0.2); border-radius: 25px;'
      if(state === 'completed') // attended
        return 'color: #6E6B7B; background-color: rgba(110, 107, 123, 0.2); border-radius: 25px;'        
      if(state === 'unaccepted')
        return 'color: red; background-color: #f3d9d9; border-radius: 25px;'        


      // on_site -> sign in
      // attended -> sign in and sign out
      // no_response -> they did not even accepted

    },
    SetAddressStyle(data, state){
      if(state === 'completed') {
        return 'align-text: left; color: #cecdd6'
      } else {
        const today = new Date()    
        if (today.getDate() === data.day && today.getMonth()+1 === data.month && today.getFullYear() === data.year)      
          return 'align-text: left; font-weight: bold;'
        else
          return 'align-text: left;'  
      }    
    },
    SetPostcodeStyle(data, state){
      if(state === 'completed'){
        return 'align-text: right; color: #cecdd6'
      } else {
        const today = new Date()  
        if (today.getDate() === data.day && today.getMonth()+1 === data.month && today.getFullYear() === data.year)      
          return 'align-text: right; font-weight: bold;'
        else
          return 'align-text: right;'
      }
    },    
    checkIfToday(data, state){
      const today = new Date()    
      if (today.getDate() === data.day && today.getMonth()+1 === data.month && today.getFullYear() === data.year)
        if(state === 'completed')
          return 'align: left; color: rgb(145, 185, 145);'
        else     
          return 'align: left; color: green;' // border-bottom-style: solid; border-bottom-color: green;
      else
        if(state === 'completed')
          return 'align: left: color: #cecdd6'
        else
          return 'align: left'
    },
    projectSelected(assignmentId, state, date) {
      console.log(state)      
      const today = new Date()

      store.commit('app/setAllowAcceptReject', true)
      /*
      || (today.getFullYear() === date.year && today.getMonth()+1 > date.month) 
      || (today.getFullYear() === date.year && today.getMonth()+1 === date.month && today.getDate() > date.day))){
        return;
      }
      */

      if (state === 'accepted' || state === 'on_site') {
        if (today.getFullYear() === date.year && today.getMonth()+1 === date.month && today.getDate() === date.day) {
          // console.log('checkin')
          store.commit('app/setIsFromProjectListPage', true)
          store.commit('app/changeUserAssignmentsID', assignmentId)
          this.$router.push('/'); 
        } else {
          // console.log('checkin')
          // store.commit('app/setIsFromProjectListPage', true)
          // store.commit('app/setIsCheckInOut', false);
          // store.commit('app/changeUserAssignmentsID', assignmentId)
          // this.$router.push('/');   

          store.commit('app/changeUserAssignmentsID', assignmentId)
          this.$router.push('/reject'); //new screen                  
        }
      } else if (state === 'offered') {
        // console.log('offered')
        // console.log(assignmentId)
        if (today.getFullYear() > date.year 
        || (today.getFullYear() === date.year && today.getMonth()+1 > date.month) 
        || (today.getFullYear() === date.year && today.getMonth()+1 === date.month && today.getDate() > date.day)){
          store.commit('app/setAllowAcceptReject', false)
        }        
        store.commit('app/changeUserAssignmentsID', assignmentId)
        this.$router.push('/acceptreject'); //new screen
      } else if (state === 'rejected') {
        // console.log('rejected')
        store.commit('app/changeUserAssignmentsID', assignmentId)
        this.$router.push('/acceptrejected'); //new screen
      }  else if (state === 'unaccepted') {
        // console.log('unaccepted')
        store.commit('app/changeUserAssignmentsID', assignmentId)
        this.$router.push('/acceptunaccepted'); //new screen
      } else if (state === 'completed') {
        store.commit('app/changeUserAssignmentsID', assignmentId)
        this.$router.push('/completed'); //new screen
      } else if (state === 'no_show') {
        store.commit('app/changeUserAssignmentsID', assignmentId)
        this.$router.push('/noshow'); //new screen
      } else if (state === 'unable_to_attend') {
        store.commit('app/changeUserAssignmentsID', assignmentId)
        this.$router.push('/unabletoattend'); //new screen
      }                 
    },
    getId(id, data){
      const today = new Date()
      if (today.getDate() === data.day && today.getMonth()+1 === data.month && today.getFullYear() === data.year){
        return 'special' //to do the automatic scroll
      }else{
        return id
      }
    },
  },
}
</script>

<style scoped>
.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  width: 100%;
}

.sub-header-item-1 {
  grid-column: 1;
  backdrop-filter: #fff;
  width: 50%;
}

.sub-header-item-2 {
  grid-column: 2;
  background: #fff;
  width: 50%;
}

.wrapper {
  overflow: auto;
  height: 70vh;
  scrollbar-width: none;
}
 </style>
